body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
  min-height: -webkit-fill-available;
  overflow: hidden;
}
body {
  height: 100vh;
  min-height: 100vh;
  height: -webkit-fill-available;
}
#root {
  height: 100vh;
  min-height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}
.hide-scroll {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
